<template>
  <div class="page-loader" v-if="!isloaded">
    <div class="cube"></div>
    <div class="cube"></div>
    <div class="cube"></div>
    <div class="cube"></div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'PageLoader',
  data: () => {
    return {
      isloaded: false,
      imagesElementsArray: [],
    }
  },
  computed: {
    ...mapGetters([
      'homeContentLoad'
    ])
  },
  created() {

  },
  methods: {
    checkLoad(){
      if(this.homeContentLoad){
        this.imagesElementsArray = [];
        console.log('home content load')
        const imagesElements = document.getElementsByTagName('img');
        for(let item of imagesElements){
          this.imagesElementsArray.push(item);
        }
        if(!this.checkAllImagesLoaded()){
          this.imagesElementsArray.forEach(item => {
            item.addEventListener('load', this.checkAllImagesLoaded)
          })
        }
      } else {
        this.isloaded = false;
      }
    },
    checkAllImagesLoaded(){
      const isLoaded = this.imagesElementsArray.every(item => item.complete);
      if(isLoaded){
        console.log('all images loaded')
        this.isloaded = true;
      }
      return isLoaded;
    }
  },

  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isloaded = true;
      }
    }
    this.checkLoad();
  },
  watch: {
    homeContentLoad() {
      this.checkLoad();
    }
  }


}
</script>

<style lang="scss" scoped>
$colors:  #F2F2F2,#C10B1C, #DB1023, #008B48;
// -----------------------------------------------------
.page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.92;
  -webkit-box-shadow: inset 0px 0px 193px 22px rgb(41,22,60);
  -moz-box-shadow: inset 0px 0px 193px 22px rgb(41,22,60);
  box-shadow: inset 0px 0px 193px 22px rgb(41,22,60);

  /*-webkit-box-shadow: inset 0px 0px 125px 18px rgb(41,22,60);
  -moz-box-shadow: inset 0px 0px 125px 18px rgb(41,22,60);
  box-shadow: inset 0px 0px 125px 18px rgb(41,22,60);*/


  background-color: black;
  z-index: 99999;
}
// -----------------------------------------------------
.cube{
  width: 40px;
  height: 40px;
  margin-right: 10px;
  @for $i from 1 through length($colors) {
    &:nth-child(#{$i}) {
      background-color: nth($colors, $i);
    }
  }
  &:first-child {
    animation: left 1s infinite;
  }
  &:last-child {
    animation: right 1s infinite .5s;
  }
}
// -----------------------------------------------------
@keyframes left {
  40% {
    transform: translateX(-60px);
  }
  50% {
    transform: translateX(0);
  }
}
@keyframes right {
  40% {
    transform: translateX(60px);
  }
  50% {
    transform: translateX(0);
  }
}
</style>